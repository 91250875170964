import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ReactGA from "react-ga";

import ScrollToTop from "./utils/ScrollToTop";

import "./App.css";
import "../src/assets/sass/landing.scss";
import "../src/assets/sass/initiative.scss";
import "../src/assets/sass/trainings.scss";
import "../src/assets/sass/gallery-questions.scss";
import "../src/assets/sass/footer.scss";
import "../src/assets/sass/courses.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "../src/assets/sass/styles.scss";

import HomePage from "./pages/home.page";
import LandingPage from "./pages/landing.page";
import SignInPage from "./pages/signin.page";
import SignUpPage from "./pages/signup.page";
import TrainingRequestsPage from "./pages/training-requests.page";
import ContactPage from "./pages/contact.page";
import ForgotPasswordPage from "./pages/forgot-password.page";
import CourseDetail from "./pages/courseDetail.page";
import Profile from "./pages/profile.page";
import ResetPasswordPage from "./pages/reset-password.page";
import SchedulePage from "./pages/schedule.page";

import { ProtectedRoute } from "./utils/routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1000, // 2 minutes
      cacheTime: 2 * 60 * 1000, // 2 minutes
    },
  },
});

const TRACKING_ID = "G-GDFRDWH5ZP";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ScrollToTop />
        <div className="App">
          <Routes>
            {/* <Route path="/" element={<LandingPage />} /> */}
            <Route path="/" element={<HomePage />} />
            <Route path="/sign-in" element={<SignInPage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/training-requests" element={<TrainingRequestsPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/course/:courseID" element={<CourseDetail />} />
            <Route path="/schedule" element={<SchedulePage />} />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
