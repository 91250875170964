import React, { useEffect, useRef, useState } from "react";
import NavbarComponent from "../components/navbar.component";

import { useSpring, animated, config, update } from "react-spring";

import { useParams } from "react-router-dom";

import "../assets/sass/course-detail.scss";
import { Col, Container, Row } from "reactstrap";
import Footer from "../components/footer.component";

import { useQuery } from "react-query";
import axios from "axios";

import { IP } from "../api/ip";
import { formatFullDate } from "../utils/formatter";
import ScheduleTable from "../components/scheduleTable.component";

import { formatInputTime } from "../utils/formatter";

import iconLang from "../assets/images/logos/icon-lang.png";
import iconLocation from "../assets/images/logos/icon-location.png";
import iconTime from "../assets/images/logos/icon-time.png";
import Company1 from "../assets/images/courseDetail/comp1.png";
import Company2 from "../assets/images/courseDetail/comp2.png";
import Company3 from "../assets/images/courseDetail/comp3.png";
import Company4 from "../assets/images/courseDetail/comp4.png";
import GeaColor from "../assets/images/gea-colored-logo.png";

import { handleScrollToTop } from "../utils/helperFunction";

const CourseDetail = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [selected, setSelected] = useState(2);
  const [heights, setHeights] = useState([null, null, null, null, null, null]);

  const sliderProps = useSpring({
    transform: `translateX(-${(selected - 1) * 100}%)`,
    height: heights[selected - 1],
    config: config.gentle,
  });

  const handleButtonClick = (buttonNumber) => {
    setSelected(buttonNumber);
    setActiveIndex(buttonNumber - 1);
  };

  const slider1ref = useRef(null);
  const slider2ref = useRef(null);

  const updateHeights = (selected) => {
    if (selected == 1 && slider1ref.current) {
      const slider1Height = slider1ref.current.clientHeight + 70;
      setHeights([slider1Height, null, null, null, null]);
    }
    if (selected == 2 && slider2ref.current) {
      const slider2Height = slider2ref.current.clientHeight + 70;
      setHeights([null, slider2Height, null, null, null]);
    }
  };

  const updateHeightOnResize = () => {
    updateHeights(selected);
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeightOnResize);
    updateHeights(selected);
  }, [selected]);

  const { courseID } = useParams();

  const URL = `${IP}/api`;

  const {
    isLoading,
    isError,
    data: courseResponse,
    isSuccess,
    refetch,
  } = useQuery(
    `course-detail-${courseID}`,
    async () => axios.get(`${URL}/courses/detail/${courseID}`),
    { cacheTime: 0, enabled: !!courseID }
  );

  const course = courseResponse?.data?.course ?? {};

  useEffect(() => {
    document.title = course?.name
      ? `${course.name} | صناع السعادة`
      : "تفاصيل الدورة | صناع السعادة";
  }, [course?.name]);

  return (
    <>
      <div className="course-detail-wrapper">
        <NavbarComponent />
        <div className="course-detail-header d-flex align-items-center justify-content-end px-5">
          <h1>{course?.name}</h1>
        </div>
      </div>
      <Container>
        <div>
          <div className="course-detail py-5">
            <h2 className="mb-4">الجدول الزمني القادم</h2>
            <Row>
              <Col xl="4"></Col>

              <Col xl="8">
                <div className="course-detail-box d-flex flex-wrap flex-row-reverse align-items-start justify-content-between">
                  {course?.upcoming_schedule ? (
                    <>
                      <div>
                        <h5 className="text-end text-green">{course?.name}</h5>
                        <div className="mt-4">
                          <div className="d-flex flex-row-reverse mb-2 align-items-center">
                            <div style={{ width: "100px", textAlign: "right" }}>
                              <h5
                                className="text-end text-green"
                                style={{
                                  borderLeft: "2px solid lightgray",
                                  paddingLeft: "10px",
                                }}
                              >
                                مدة{" "}
                                <img src={iconTime} style={{ width: "30px" }} />
                              </h5>
                            </div>
                            <h5
                              className="text-start text-green me-3"
                              dir="rtl"
                            >
                              {course?.upcoming_schedule?.duration ?? "-"} أيام
                            </h5>
                          </div>
                          <div className="d-flex flex-row-reverse mb-2 align-items-center">
                            <div style={{ width: "100px", textAlign: "right" }}>
                              <h5
                                className="text-end text-green"
                                style={{
                                  borderLeft: "2px solid lightgray",
                                  paddingLeft: "10px",
                                }}
                              >
                                الموقع{" "}
                                <img
                                  src={iconLocation}
                                  alt="language"
                                  style={{ width: "30px", color: "red" }}
                                />
                              </h5>
                            </div>

                            <h5 className="text-end text-green me-3">
                              {course?.upcoming_schedule?.location?.name ?? "-"}
                            </h5>
                          </div>
                          <div className="d-flex flex-row-reverse mb-2 align-items-center">
                            <div style={{ width: "100px", textAlign: "right" }}>
                              <h5
                                className="text-end text-green ms-0"
                                style={{
                                  borderLeft: "2px solid lightgray",
                                  paddingLeft: "10px",
                                }}
                              >
                                اللغة
                                <img
                                  src={iconLang}
                                  alt="language"
                                  style={{ width: "40px" }}
                                />
                              </h5>
                            </div>

                            <h5 className="text-end text-green me-3">
                              {course?.upcoming_schedule?.language ?? "-"}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div className="course-detail-date-badge mt-4 mt-md-0">
                        <div className="purple-box ">
                          <div className="d-flex align-items-center justify-content-between mb-4">
                            <div className="circle"></div>
                            <div className="circle"></div>
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <h5 dir="rtl">
                              {formatFullDate(
                                course?.upcoming_schedule?.start_date
                              )}
                            </h5>
                            <h5 dir="rtl">
                              {formatFullDate(
                                course?.upcoming_schedule?.end_date
                              )}
                            </h5>
                          </div>
                        </div>
                        <div className="green-box d-flex align-items-center justify-content-center">
                          <h5 className="mb-0" dir="rtl">
                            {course?.upcoming_schedule?.start_time
                              ? formatInputTime(
                                  course?.upcoming_schedule?.start_time
                                )
                              : ""}
                            {course?.upcoming_schedule?.end_time
                              ? `- ${formatInputTime(
                                  course?.upcoming_schedule?.end_time
                                )}`
                              : ""}
                          </h5>
                        </div>
                      </div>
                    </>
                  ) : (
                    "لا يوجد جدول زمني قادم"
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="trainings mb-5">
          <div className="row">
            <ul className="tabs">
              <li
                onClick={() => handleButtonClick(1)}
                style={{ fontFamily: "thesans-plain, sans-serif" }}
                className={
                  activeIndex === 0 ? "gray active" : "gray"
                }
              >
                الجدول
              </li>
              <li
                onClick={() => handleButtonClick(2)}
                style={{ fontFamily: "thesans-plain, sans-serif" }}
                className={
                  activeIndex === 1 ? "orange active" : "orange"
                }
              >
                الوصف
              </li>
            </ul>
          </div>

          {activeIndex === 1 && (
            <div
              className="slide slide-5"
              style={{ justifyContent: "flex-end", padding: "10px 50px" }}
            >
              <span id="2">
                <h6 className="text-end text-white p-3 py-5 mb-0">
                  <div
                    dir="rtl"
                    dangerouslySetInnerHTML={{
                      __html: course?.description,
                    }}
                  />
                </h6>
              </span>
            </div>
          )}

          {activeIndex === 0 && (
            <div className="p-3 bg-gray">
              <ScheduleTable homeValues={{ course: course?.name }} />
            </div>
          )}
        </div>
        <section className="course-detail-logos-section py-5 mx-3">
          <h2 className="mb-4 text-end mb-4">: الشهادة معتمدة من</h2>
          <div className="course-detail-logos py-5 mb-5 row ">
            <div className="col ">
              <div className="row justify-content-center">
                {/* <div className="course-detail-company-logo order-2 order-xl-1 col-md-6 col-xl-2 mt-3 mt-xl-0">
                  <img src={Company4} />
                </div>
                <div className="course-detail-company-logo order-3 order-xl-2 col-md-6 col-xl-2 mt-3 mt-xl-0">
                  <img src={Company3} />
                </div>
                <div className="course-detail-company-logo order-4 order-xl-3 col-md-6 col-xl-2 mt-3 mt-xl-0">
                  <img src={Company2} />
                </div>
                <div className="course-detail-company-logo order-5 order-xl-4 col-md-6 col-xl-2 mt-3 mt-xl-0">
                  <img src={Company1} />
                </div> */}
                <div className="course-detail-company-gea order-1 order-xl-5 d-flex align-items-center justify-content-center col-md-6 col-xl-2 mt-3 mt-xl-0">
                  <img src={GeaColor} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Footer scroll={handleScrollToTop} />
    </>
  );
};

export default CourseDetail;
