import React, { useEffect, useState } from "react";

// import HCLogonew from '../assets/images/logos/happiness-creators-logo-2.png'

import HCLogo from '../assets/images/logos/happiness-creators-24.png'
// import HCLogonew from '../assets/images/logos/happiness-creators-logo.png'



import { RxBell, RxHamburgerMenu } from "react-icons/rx";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Link } from "react-router-dom";

import { useStore } from "../api/useStore";
import { useQuery } from "react-query";
import axios from "axios";
import useAxios from "../api/useAxios";
import NoteModal from "./note-modal.component";

const NavbarComponent = ({
  activePage,
  scroll,
  handleScrollToCoursesOnHome,
}) => {
  const userStatus = useStore((state) => state?.userStatus);
  const [scrolled, setScrolled] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [noteData, setNoteData] = useState(null);
  const [noteCount, setNoteCount] = useState("");

  const toggle1 = () =>
    setDropdownOpen1((prevState) => {
      if (prevState) {
        refecthAllRead();
        setNoteCount("");
      }
      return !prevState;
    });
  const toggle2 = () =>
    setDropdownOpen2((prevState) => {
      if (prevState) {
        refecthAllRead();
      }
      return !prevState;
    });

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    if (modal) {
      setNoteData(null);
    }
    setModal(!modal);
  };

  const {
    isLoading: allReadLoading,
    isError: allReadError,
    data: allReadResponse,
    isSuccess: allReadSuccess,
    refetch: refecthAllRead,
  } = useQuery(
    "read-all-notifications",
    async () => api.post(`/notifications/read-notifications`),
    {
      cacheTime: 0,
      enabled: false,
    }
  );

  const logOut = () => {
    useStore.setState({
      userStatus: false,
      accessToken: false,
      refreshToken: false,
    });
  };

  const api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(
    "get-notifications",
    async () => api.get(`/notifications/main-notifications`),
    {
      cacheTime: 0,
      enabled: userStatus,
    }
  );

  const unSortedNotifications = response?.data ?? [];
  const notifications = [...unSortedNotifications].sort((a, b) => {
    const timestampA = new Date(a.timestamp);
    const timestampB = new Date(b.timestamp);

    return timestampB - timestampA;
  });

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY < 1; // Adjust the value as needed
      setScrolled(!isTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (noteData) {
      toggleModal();
    }
  }, [noteData]);

  useEffect(() => {
    let count = 0;
    response?.data?.map((note) => {
      if (!note?.is_read) {
        count++;
      }
    });
    setNoteCount(count);
  }, [response]);

  return (
    <div className={`navbar w-100 ${scrolled && "navbar-shadow"} `}>
      <div className="container-fluid">
        <div className="row align-items-center w-100">
          <div className="col-2 col-md-1 d-lg-none d-flex justify-content-end"></div>

          <div className="col-9 col-md-3 col-lg-3 d-none d-lg-block text-start hc-logo-wrapper">
            
          </div>

          <div className="d-none d-lg-block col-lg-6">
            <ul
              dir="rtl"
              className="nav-items mb-0 px-0 justify-content-center gap-4"
            >
              

              

              <Link to={"/"}>
                <li
                  className={
                    activePage === "home" ? "active px-3 py-1" : "px-3 py-1"
                  }
                >
                  الرئيسية
                </li>
              </Link>

              {activePage === "home" ? (
                <li
                  className="menu-item px-3 py-1  px-2"
                  onClick={handleScrollToCoursesOnHome}
                >
                  الدورات
                </li>
              ) : (
                <li className="menu-item px-3 py-1  px-2">
                  <Link to={activePage !== "home" && "/#courses"}>الدورات</Link>
                </li>
              )}

              <Link to={"/schedule"}>
                <li
                  className={
                    activePage === "schedule" ? "active px-3 py-1" : "px-3 py-1"
                  }
                >
                  جدول
                </li>
              </Link>
           
              <Link to={"/training-requests"}>
                <li
                  className={
                    activePage === "trainingRequests" ? "active px-3 py-1" : "px-3 py-1"
                  }
                >
                  طلبات التدريب	
                </li>
              </Link>

              <Link to={"/contact-us"}>
                <li
                  className={
                    activePage === "contact" ? "active px-3 py-1" : "px-3 py-1"
                  }
                >
                  اتصل بنا
                </li>
              </Link>
            </ul>
          </div>

          <div className="col-8 col-md-10 col-lg-3  text-lg-end text-center hc-logo-wrapper">
            <Link to="/" style={{ width: "180px" }} className="d-lg-none">
              <img
                className="hc-logo mt-2"
                src={HCLogo}
                style={{ objectFit: "contain" }}
              />
            </Link>

            <div className="d-none d-lg-flex align-items-lg-center justify-content-lg-end gap-4 ">
              {userStatus && (
                <Dropdown isOpen={dropdownOpen1} toggle={toggle1}>
                  <DropdownToggle
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  >
                    {noteCount ? <div className="note-count"></div> : null}
                    <RxBell
                      className="text-green d-none d-lg-block"
                      style={{ width: "22px", height: "22px" }}
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className="mt-2 note-menu"
                    style={{
                      width: "280px",
                      height: "350px",
                      overflow: "scroll",
                    }}
                  >
                    <DropdownItem
                      className="text-end fw-bold"
                      style={{ fontSize: "20px" }}
                      header
                    >
                      إشعارات
                    </DropdownItem>
                    {isLoading ? (
                      <div className="d-flex align-items-center justify-content-center my-5">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : notifications?.length > 0 ? (
                      notifications?.map((note, index) => (
                        <DropdownItem
                          className={`text-end mt-3 note-item ${
                            !note?.is_read && "unread-note"
                          }`}
                          key={index}
                        >
                          <div onClick={() => setNoteData(note)}>
                            <p className="mb-0 rtl-text-truncate">
                              {note?.title}
                            </p>
                          </div>
                        </DropdownItem>
                      ))
                    ) : (
                      <div className="d-flex align-items-center justify-content-center"></div>
                    )}
                  </DropdownMenu>
                </Dropdown>
              )}

              <Link to="/" style={{ width: "250px" }}>
                <img
                  className="hc-logo"
                  src={HCLogo}
                  style={{ objectFit: "contain", marginBottom: '-15px' }}
                />
              </Link>
            </div>
          </div>

          {/* Mobile Navbar */}
          <div className="col-2 col-md-1 d-lg-none d-flex justify-content-end  ">
            <div className="d-flex align-items-center gap-3 gap-sm-4">
              {userStatus && (
                <Dropdown
                  isOpen={dropdownOpen2}
                  toggle={toggle2}
                  style={{ position: "relative" }}
                >
                  <DropdownToggle
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  >
                    {noteCount ? <div className="note-count"></div> : null}
                    <RxBell
                      className="text-green d-block d-lg-none"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className="mt-2 note-menu"
                    style={{
                      width: "280px",
                      height: "350px",
                      overflow: "scroll",
                    }}
                  >
                    <DropdownItem
                      className="text-end fw-bold"
                      style={{ fontSize: "20px" }}
                      header
                    >
                      إشعارات
                    </DropdownItem>
                    {isLoading ? (
                      <div className="d-flex align-items-center justify-content-center my-5">
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : notifications?.length > 0 ? (
                      notifications?.map((note, index) => (
                        <DropdownItem
                          className={`text-end mt-3 note-item ${
                            !note?.is_read && "unread-note"
                          }`}
                        >
                          <div onClick={() => setNoteData(note)}>
                            <p className="mb-0 rtl-text-truncate">
                              {note?.title}
                            </p>
                          </div>
                        </DropdownItem>
                      ))
                    ) : (
                      <div className="d-flex align-items-center justify-content-center"></div>
                    )}
                  </DropdownMenu>
                </Dropdown>
              )}
              <RxHamburgerMenu
                className="hamburger"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              />
            </div>

            <div
              className="offcanvas hamburger-body offcanvas-end"
              data-bs-scroll="true"
              tabIndex="-1"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header">
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <div className="container">
                  <div className="menu-item text-white px-2 py-3">
                    <Link
                      className={activePage === "home" ? "active" : ""}
                      to={"/"}
                    >
                      الرئيسية
                    </Link>
                  </div>
                  {window.location.pathname == "/" ? (
                    <div
                      className="menu-item text-white px-2 py-3"
                      onClick={handleScrollToCoursesOnHome}
                    >
                      الدورات
                    </div>
                  ) : (
                    <Link to={window.location.pathname !== "/" && "/#courses"}>
                      <div className="menu-item text-white px-2 py-3">
                        الدورات
                      </div>
                    </Link>
                  )}

                  <div className="menu-item text-white px-2 py-3">
                    <Link
                      className={activePage === "schedule" ? "active" : ""}
                      to={"/schedule"}
                    >
                      جدول
                    </Link>
                  </div>
               
                  <div className="menu-item text-white px-2 py-3">
                    <Link
                      className={activePage === "trainingRequests" ? "active" : ""}
                      to={"/training-requests"}
                    >
                      طلبات التدريب	
                    </Link>
                  </div>

                  <div className="menu-item text-white px-2 py-3">
                    <Link
                      className={activePage === "contact" ? "active" : ""}
                      to={"/contact-us"}
                    >
                      {" "}
                      اتصل بنا
                    </Link>
                  </div>

                  <div className="menu-item text-white px-2 py-3">
                    {userStatus ? (
                      <Link
                        className={activePage === "profile" ? "active" : ""}
                        to="/profile"
                      >
                        الحساب التعريفي
                      </Link>
                    ) : (
                      <Link
                        className={activePage === "login" ? "active" : ""}
                        to="/sign-in"
                      >
                        تسجيل الدخول
                      </Link>
                    )}
                  </div>
                  {userStatus ? (
                    <Link to="/sign-up">
                      <button onClick={logOut} className="nav-btn sign-up-btn">
                        تسجيل الخروج
                      </button>
                    </Link>
                  ) : (
                    <Link to="/sign-up">
                      <button className="nav-btn sign-up-btn">اشتراك</button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoteModal modal={modal} toggleModal={toggleModal} data={noteData} />
    </div>
  );
};

export default NavbarComponent;
