import React from "react";
import NavbarComponent from "../components/navbar.component";
import Footer from "../components/footer.component";
import { handleScrollToTop } from "../utils/helperFunction";
import { Label, Input } from "reactstrap";
import { companyRegistrationSchema } from "../validation-schema";
import { useFormik } from "formik";
import { IP } from "../api/ip";
import axios from "axios";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {Spinner} from "reactstrap";

const TrainingRequestsPage = () => {
    
    document.title = "طلبات التدريب | صناع السعادة";

    const sendGroupRequest = (formData) => {
        const URL = `${IP}/api`;
        return axios.post(`${URL}/courses/create-group-registration`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        });
    };

      const sendGroupRequestMutation = useMutation(sendGroupRequest, {
        onSuccess: (data) => {
          toast.success("مكتمل");
          resetForm();
        },
        onError: (data) => {
          if (data?.response?.status === 400 && !!data?.response?.data?.message) {
            toast.error(data.response.data.message);
          } else if (
            data?.response?.status === 500 &&
            !!data?.response?.data?.message
          ) {
            toast.error(data.response.data.message);
          } else {
            toast.error("هناك خطأ ما");
          }
        },
      });

    const onSubmit = () => {
    sendGroupRequestMutation.mutate(values);
  };



  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues: {
      companyRepresentative: "",
      companyName: "",
      companySector: "",
      jobTitle: "",
      phone: "",
      email: "",
      noOfTrainees: "",
      traineesFile: "",
      schedule: "",
    },
    validationSchema: companyRegistrationSchema,
    onSubmit,
    enableReinitialize: true,
  });


  const URL = `${IP}/api`;

  const {
    isLoading,
    isError,
    data,
    isSuccess,
    refetch,
  } = useQuery("schedules", async () => axios.get(`${URL}/courses/schedules/`));

  const schedules = data?.data?.schedules ?? [];

  console.log(values)

  // const dropdownOptions = schedules.map((schedule) => 
  //   {"value": schedule.id, "text": `(${schedule?.end_date} - ${schedule?.start_date}) ${schedule?.course?.name}`}
  // );



  return (
    <>
      <div className="contact-wrapper">
        <NavbarComponent activePage={"trainingRequests"} />
      </div>
      <div className="container">
        <div className="">
          <h2 className="text-end text-orange mt-4">طلبات التدريب</h2>
          <p className="text-end" dir="rtl">
            في هذه الخدمة تستطيع الشركات طلب دورة تدريبية بناءً على احتياجات الموظفين لتسريع إجراءات التدريب لهم<br/> طلبات التدريب هي لـ:
          </p>

          <ol className="text-end training-requests-list" dir="rtl">
            <li>⁠ ⁠القطاعات الحكومية</li>
            <li> ⁠القطاعات الخاصة</li>
            <li>⁠المجموعات الخاصة (مجموعة مكونة من 30 شخص أو أكثر)</li>
          </ol>
        </div>

        <div>
          <form onSubmit={handleSubmit}>
            <div className="row flex-row-reverse">
              <div className="col-sm-6 text-end">
                <Label className="contact-form-label">الاسم</Label>
                <Input
                  dir="rtl"
                  value={values.companyRepresentative}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.companyRepresentative && touched.companyRepresentative
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  placeholder="أدخل الاسم"
                  name="companyRepresentative"
                />
                {errors.companyRepresentative && touched.companyRepresentative && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.companyRepresentative}
                  </div>
                )}
              </div>

              <div className="col-sm-6 text-end">
                <Label className="contact-form-label">اسم الجهة</Label>
                <Input
                  dir="rtl"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.companyName && touched.companyName
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  placeholder="أدخل اسم الجهة"
                  name="companyName"
                />
                {errors.companyName && touched.companyName && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.companyName}
                  </div>
                )}
              </div>

              <div className="col-sm-6 text-end">
                <Label className="contact-form-label">قطاع </Label>
                <Input
                  dir="rtl"
                  value={values.companySector}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.companySector && touched.companySector
                    ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  placeholder="أدخل قطاع "
                  name="companySector"
                />
                {errors.companySector && touched.companySector && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.companySector}
                  </div>
                )}
              </div>

              <div className="col-sm-6 text-end">
                <Label className="contact-form-label">المسمى الوظيفي</Label>
                <Input
                  dir="rtl"
                  value={values.jobTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.jobTitle && touched.jobTitle
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  placeholder="أدخل المسمى الوظيفي"
                  name="jobTitle"
                />
                {errors.jobTitle && touched.jobTitle && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.jobTitle}
                  </div>
                )}
              </div>

              <div className="col-sm-6 text-end">
                <Label className="contact-form-label">رقم الاتصال</Label>
                <Input
                  dir="rtl"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.phone && touched.phone
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  placeholder="أدخل رقم الاتصال"
                  name="phone"
                />
                {errors.phone && touched.phone && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.phone}
                  </div>
                )}
              </div>

              <div className="col-sm-6 text-end">
                <Label className="contact-form-label">البريد الإلكتروني</Label>
                <Input
                  dir="rtl"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  className={
                    errors.email && touched.email
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  placeholder="أدخل البريد الإلكتروني"
                  name="email"
                />
                {errors.email && touched.email && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.email}
                  </div>
                )}
              </div>

              <div className="col-sm-6 text-end">
                <Label className="contact-form-label">عدد المتدربين</Label>
                <Input
                  dir="rtl"
                  value={values.noOfTrainees}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                  className={
                    errors.noOfTrainees && touched.noOfTrainees
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  placeholder="أدخل عدد المتدربين"
                  name="noOfTrainees"
                />
                {errors.noOfTrainees && touched.noOfTrainees && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.noOfTrainees}
                  </div>
                )}
              </div>


             <div className="col-sm-6 text-end">
                <Label className="contact-form-label">اسم الدورة</Label>
                {isLoading ?
                <span>
                    <br />
                    <Spinner></Spinner>
                </span>
                :
                <Input
                value={values.schedule}
                onChange={handleChange}
                onBlur={handleBlur}
                type="select"
                className={
                    errors.schedule && touched.schedule
                    ? "text-end form-control form-control-icon is-invalid login-field col-6"
                    : "text-end form-control input-text form-control-icon login-field col-6"
                }
                name="schedule"
                >
                  <option dir="rtl" value="" disabled>
                    اختر اسم الدورة
                  </option>

                  {schedules.map((schedule) => (
                      <option className="text-end" value={schedule.id}>{`(${schedule?.end_date} - ${schedule?.start_date}) ${schedule?.course?.name}`}</option>
                    ))}
                </Input>
                }
                {errors.schedule && touched.schedule && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.schedule}
                  </div>
                )}
              </div>


              <div className="col-sm-6 text-end">
                <p className="text-end mb-0 mt-2" dir="rtl">الرجاء تعبئة بيانات الموظفين المرشحين في ملف اكسل وإرفاقه ادناه</p>
                <p>
                   
                <span dir="rtl">1. الاسم</span>
                <br />
                    <span dir="rtl">2. رقم الهوية</span>
                    <br />
                    <span dir="rtl">3. البريد الإلكتروني</span>
                </p>

                <Input
                  dir="rtl"
                  onChange={(event) => {
                    setFieldValue("traineesFile", event.currentTarget.files[0]);
                  }}
                  onBlur={handleBlur}
                  type="file"
                  accept=".xls,.xlsx"
                  className={
                    errors.traineesFile && touched.traineesFile
                      ? "form-control form-control-icon is-invalid login-field col-6"
                      : "form-control input-text form-control-icon login-field col-6"
                  }
                  name="traineesFile"
                />
                {errors.traineesFile && touched.traineesFile && (
                  <div dir="rtl" className="invalid-feedback">
                    {errors.traineesFile}
                  </div>
                )}

                <p dir="rtl">ملاحظة: يجب على جميع الموظفين التسجيل في التسجيل الفردي. </p>
              </div>
            </div>
            <div className="text-end mt-3">
            {sendGroupRequestMutation.isLoading ? 
            'Please wait' : 
              <button type="submit" className="table-register-btn">
                إرسال الطلب
              </button>
            }

            </div>
          </form>
        </div>
      </div>

      <Footer scroll={handleScrollToTop} />
    </>
  );
};

export default TrainingRequestsPage;
