import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup.string().email("أدخل بريد إلكتروني متاح").required("مطلوب"),
  password: yup.string().required("مطلوب"),
});

export const candidateSchema = yup.object().shape({
  ar_name: yup.string().required("مطلوب"),
  en_name: yup.string().required("مطلوب"),
  nationality: yup.string().required("مطلوب"),
  phone: yup.string().required("مطلوب"),
  email: yup.string().email("أدخل بريد إلكتروني متاح").required("مطلوب"),
  password: yup.string().required("مطلوب"),
  id_type: yup.string().required("مطلوب"),
  id_no: yup.number().required("مطلوب"),
  qualification: yup.string().required("مطلوب"),
  employment_status: yup.string().required("مطلوب"),
  age: yup
    .number()
    .positive("يجب أن يكون العمر رقمًا موجبًا")
    .integer("يجب أن يكون العمر عددًا صحيحًا")
    .min(0, "يجب أن يكون عمرك 18 عامًا أو أكبر")
    .required("مطلوب"),
  gender: yup.string().required("مطلوب"),
  residence: yup.string().required("مطلوب"),
  city: yup.string().required("مطلوب"),
  hearFrom: yup.string().required("مطلوب"),
  job_title: yup.string().required("مطلوب"),
  organization: yup.string().required("مطلوب"),
  years_of_experience: yup.number().typeError('يجب أن تكون سنوات الخبرة رقمًا').positive("يجب أن تكون السنة رقمًا موجبًا").integer('يجب أن تكون السنة عددًا صحيحًا').required("مطلوب"),
});



export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email("أدخل بريد إلكتروني متاح").required("مطلوب"),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("مطلوب")
    .min(8, "يجب أن تكون كلمة المرور 8 أحرف على الأقل"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "يجب أن تتطابق كلمات المرور")
    .required("مطلوب"),
});

export const contactUsSchema = yup.object().shape({
  name: yup.string().required("مطلوب"),
  email: yup.string().email("أدخل بريد إلكتروني متاح").required("مطلوب"),
  message: yup.string().required("مطلوب"),
});


export const profileSchema = yup.object().shape({
  name: yup.string().required("مطلوب"),
  city: yup.string().required("مطلوب"),
  country: yup.string().required("مطلوب"),
});


export const passwordSchema = yup.object().shape({
  old_password: yup.string().required("مطلوب"),
  new_password: yup
    .string()
    .required("مطلوب")
    .min(8, "يجب أن تكون كلمة المرور 8 أحرف على الأقل"),
});


const MAX_FILE_SIZE = 20 * 1024 * 1024; // 10MB

const SUPPORTED_EXCEL_FORMATS = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-excel' // .xls
];

export const companyRegistrationSchema = yup.object().shape({
  companyRepresentative: yup.string().required("مطلوب"),
  companyName: yup.string().required("مطلوب"),
  companySector: yup.string().required("مطلوب"),
  jobTitle: yup.string().required("مطلوب"),
  phone: yup
    .string()
    .required("مطلوب"),
  email: yup
    .string()
    .email("يرجى إدخال عنوان بريد إلكتروني صالح")
    .required("مطلوب"),
  noOfTrainees: yup
    .number()
    .typeError("يجب أن يكون عدد المتدربين رقمًا")
    .integer("عدد المتدربين يجب أن يكون رقمًا صحيحًا")
    .positive("عدد المتدربين يجب أن يكون رقمًا موجبًا")
    .required("مطلوب"),
  traineesFile: yup.mixed()
  .required('يرجى تحميل ملف Excel')
  .test('fileFormat', 'صيغة الملف غير مدعومة. يرجى تحميل ملف Excel بصيغة (.xls أو .xlsx)', 
    value => value && SUPPORTED_EXCEL_FORMATS.includes(value.type)
  )
  .test('fileSize', 'حجم الملف كبير جدًا. الحد الأقصى للحجم هو 20 ميجابايت',
    value => value && value.size <= MAX_FILE_SIZE
  )
});





